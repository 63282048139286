<template>
    <v-container fluid  class="my-10" id="Servicios">
      <v-row class="" >
        <v-col cols=12 md=6 class="d-flex flex-column justify-space-around"  >
            <div class="d-flex justify-center mb-5 " >
              <v-img 
                eager
                alt="Plan Do"
                title="Plan-Do"
                src="/images/isotipo_gris.png"
                class="pb-0 "
                transition="fade-transition"
                max-width="clamp(50px, 25vw,100px)"        

                
              />
              <!--:transition="scale-transition"-->
            </div>
            <div class="d-flex justify-center px-5" >
              <span class="text-responsive-h primary--text font-weight-normal ">HACEMOS</span>
            </div>
        </v-col>
 
        <v-col cols=12 md=6 class="d-flex flex-column justify-end " >
          <p class="body-2 mb-0 font-italic">
Trabajamos junto con nuestros clientes, construyendo una
relación de confianza en el que podamos co-diseñar y
generar estrategias para el desarrollo de estructuras
organizacionales sólidas, pensando siempre en el bienestar
integral de los sistemas y de las personas que lo integran.
          </p>
        </v-col>
      </v-row>
      <v-row class=" mt-10">
        <v-col v-for="(servicio, index) in servicios" cols=12 md=6 :offset-lg="offset(index)" lg=4 class="" :key="index" >
          <v-hover v-slot="{ hover }">
            <v-card :elevation="hover ? 7: 1"  tile class="py-5 translateY" :class="hover ? 'grey lighten-3':'white'" @click="Goto(servicio.id)">
              <div class="d-flex justify-center">
                <img
                  class=""
                  style="width:80px; height:80px"
                  :src="servicio.img"
                />
              </div>
              <div class="d-flex body-1 justify-center text-center font-weight-normal px-5 pt-10 pb-5 "  >{{servicio.title}}</div>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
      <template v-for="(servicio, index) in servicios">
        <v-spacer  :id="servicio.id" :key='`spacer_${index}`' />
        <v-row class="mt-10 " :class="[servicio.bg]"  :key='`title${index}`'>
          <v-col cols=12 class="body-1 white--text font-weight-normal" v-html="servicio.title"></v-col>
        </v-row>
        <v-row class="white mt-5" :key='`subtitle${index}`'>
          <v-col cols=12 class="body-2 black--text divider_green font-weight-normal">{{servicio.subtitle}}</v-col>
        </v-row>
        <v-row class="" :key='`row${index}`'>

          <v-col v-for="(card, idx) in servicio.items" cols=12 md=6 :offset-lg="offset(idx)" lg=4 class="mt-5" :key='`col${index}_${idx}`' >
            <v-hover v-slot="{ hover }">
            <v-card :elevation="hover ? 7: 1" tile class="py-5 translateY" height="100%" :class="hover ? 'grey lighten-5 ':'white'">
              <div class="d-flex align-center" >
                <div class="mx-5">
                  <img height="40px" width="40px" :src="card.img" />
                </div>
                <div class="body-1 font-weight-normal"  v-html="card.title" ></div>
              </div>
              <v-card-text class="body-2 black--text">
                <p v-for="(item, i) in card.text" :key="i">· {{item}}</p>
              </v-card-text>
            </v-card>
            </v-hover>
          </v-col>


        </v-row>
      </template>
    </v-container>
</template>

<script>
import 'animate.css'

export default {
  name: 'Servicios',
  components: {},

 

  data: () => ({
    servicios:[
      {
        id:'seleccion_atraccion',
        title:'SELECCIÓN & ATRACCIÓN DE TALENTO',
        img:'/images/iconos/seleccion_atraccion.svg',
        bg:'primary',
        subtitle:'Traccionamos al mejor candidato para cada organización',
        items:[
          {
            title:'Búsquedas Ejecutivas',
            img:'/images/iconos/servicios01.png',
            text:[
              'Hunting: somos un equipo de profesionales especializados en búsquedas Gerenciales, de Mandos Medios y Especialistas.',
              'Detectamos proactivamente candidatos target en el mercado.',
              'Somos especialistas en el mercado de talentos regional y seleccionamos al mejor candidato para cada organización.'
            ]
          },
          {
            title:'Búsquedas Profesionales',
            img:'/images/iconos/servicios02.png',
            text:[
              'Amplia trayectoria en búsquedas de profesionales para todas las áreas funcionales de una organización.',
              'Buscamos a los mejores candidatos en función a sus capacidades técnicas y características personales.',
              'Trabajamos con el objetivo de ser aliados y socios estratégicos de nuestros clientes en el proceso de búsqueda, para impactar de forma positiva en los resultados del negocio.'
            ]
          },
          {
            title:'Búsquedas de Personal Operativo',
            img:'/images/iconos/servicios03.png',
            text:[
              'Realizamos procesos individuales y masivos.',
              'Contamos con amplia experiencia en programas de ingresos masivos, tanto para cobertura de vacaciones como para procesos extraordinarios de las empresas, fundamentalmente en industrias pesadas.'
            ]
          },
          {
            title:'Programas de Trainees y Jóvenes Profesionales',
            img:'/images/iconos/servicios04.png',
            text:[
              'Somos expertos en procesos de atracción y desarrollo de jóvenes talentos.',
              `Buscamos, identificamos y captamos candidatos con potencial de desarrollo, dentro de la organización, con la doble finalidad de que en el futuro ocupen roles estratégicos dentro de la misma yque sean capacitados y potenciados.`
            ]
          },
        ]
      },
      {
        id:'crecimiento_organizacional',
        title:'CRECIMIENTO ORGANIZACIONAL',
        img:'/images/iconos/crecimiento_desarrollo.svg',
        bg:'grey',
        subtitle:'El foco de importancia en la experiencia del colaborador, es clave para el crecimiento y competitividad de la organización.',
        items:[
          {
            title:'Gestión del cambio',
            img:'/images/iconos/servicios06.png',
            text:[
              `Entrenamos diferentes niveles de inteligencia emocional, promovemos sinergias positivas a través de prácticas y herramientas vivenciales, donde desarrollamos equipos de alto rendimiento.`,
              `Llevamos a delante el planeamiento, creación e implementación de estrategias de transformación cultural.`,
              `Desarrollamos Agentes de Cambio dentro de la organización.`,
              `Realizamos el diseño y gestión de capacitación y talleres de entrenamiento de alto impacto.`
            ]
          },
          {
            title:'Desarrollo Directivo y del Liderazgo',
            img:'/images/iconos/servicios08.png',
            text:[
              `Potenciamos el desarrollo de Mandos Medios y Alta dirección, gestionando sus recursos para enfocarse en un ambiente "sano" que permita a cada miembro del equipo mostrar su mejor versión.`,
              `Implementamos herramientas prácticas y sencillas de aplicación diaria en equipos de trabajo, para potenciar líderes.`,
              `Maximizamos el potencial en gestión del equipo, mejorando la efectividad, cuidando las relaciones colaborativas y velando por el bienestar de todos los involucrados.`
            ]
          },
          {
            title:'Gestión de Equipos',
            img:'/images/iconos/servicios07.png',
            text:[
              `Trabajamos el desarrollo de equipos desde su nivel actual de potencial.`,
              `Realizamos el diagnóstico de recursos individuales y grupales - vs. cultura de trabajo.`,
              `Implementamos herramientas que permiten a los equipos la transición de su zona de desarrollo actual, a una zona de desarrollo próximo.`
            ]
          },
          {
            title:'Diseño de Capacitaciones',
            img:'/images/iconos/servicios09.png',
            text:[
              `Diseñamos formaciones a medida atendiendo la demanda de cada empresa.`,
              `Acompañamos a los equipos de colaboradores en la mejora de su desempeño, brindando herramientas efectivas a la hora de gestionar su trabajo.`, 
              `Revisamos conceptos, validamos conocimientos y diseñamos el plan de acción, enfocado en el cumplimiento de objetivos.`
            ]
          },
          {
            title:'Metodología LEGO® SERIOUS PLAY®',
            img:'/images/iconos/servicios10.png',
            text:[
              `En esta metodología, el 100 % del tiempo, el 100 % de las personas participan activamente, de manera colaborativa, expresando lo que piensan y sienten.`,
              `La utilizamos para construir una solución con criterios comunes de decisión, con la que todos puedan convivir, para implementar una solución sostenible en el tiempo.`, 
              `Con Lego SP se desarrolla la estrategia en tiempo real, de manera rápida y ágil.`,
              `Ayuda a las personas a potenciar y construir una inteligencia colectiva.`
            ]
          },
          {
            title:'Metodologías Ágiles',
            img:'/images/iconos/servicios11.png',
            text:[
              `Creemos que la calidad de las soluciones, se obtiene por experimentar con procesos, que se perfeccionan haciendo, equivocándose y corrigiendo sobre la marcha y en base a la prueba concreta durante su implementación.`,
              `Algunas de las técnicas que utilizamo son: Srum, Design Thinking, Business Model Canvas, LEGO® SERIOUS PLAY® o Lean, entre otras.`,
              `Trabajamos con herramientas que generan mayor participación activa, más involucramiento y mayor compromiso en los participantes.`
            ]
          },
          {
            title:'Gestión del Talento',
            img:'/images/iconos/servicios05.png',
            text:[
              `Realizamos mapeos organizacionales.`,
              `Desarrollamos herramientas a medida, para detectar población High Potencial Talent.`,
              `Nos especializamos en estimación de potencial.`
            ]
          },
        ]
      }
    ]
  }),

  created: function () {
  },

  mounted: function () {
  },

  watch: {

  },
  computed:{
    //...mapGetters(['ROOT_API','MENU','getSnackBar','getSESSION','getUser']),
  },

  methods: {
    offset (idx) {
      return (idx % 2 == 0 ? 1 : 2 )
    },

    onResize () {
      //console.log(this.$vuetify.breakpoint)
      let {xs, sm, md, lg, xl,mobile} =  this.$vuetify.breakpoint      
      return sm || xs
      //this.$store.dispatch("resizeApp",{ x: window.innerWidth, y: window.innerHeight, xs, sm, md, lg, xl,mobile })
    },

    Goto (url){
      this.$vuetify.goTo(`#${url}`,{duration: 600,offset: 0, easing: 'easeInOutCubic'})
    },
  },

};
</script>

<style lang="scss" scope>

.text-responsive-h {
  font-size: clamp(4em, 10vw - 1em,8em) !important;
  line-height: .7!important;
}

.divider_green  {
  position: relative;
  
  &::before {
    content:"";
    position: absolute;
    bottom:0;
    background: linear-gradient(to right, #c9d401 0%, #FFFF 80%);//radial-gradient(circle, #c9d401 0%, rgba(255,255,255,1) 98%);
    width: calc(100% - 24px);
    height: 2px;

  }
}

.v-card__title {
  word-break: normal!important;
}


.v-card {
  position:relative;


  &::before {
    position:absolute;
    //content:'';
    top:0;
    left:-6px;
    width: 6px;
    height: 100%;
    //background:radial-gradient(ellipse at center, red 0, transparent 50%);
    background-color: #AAA;
    filter:blur(1px);

    //background: radial-gradient(ellipse at top, #e66465, transparent),
    //        radial-gradient(ellipse at bottom, #4d9f0c, transparent);
    
    border-radius: 100% 0 0 100% / 50%;
    //0 100% 100% 0 / 50%

  }
}

.translateY {
    transition: -webkit-transform .25s;
    transition: transform .25s;
    transition: transform .25s,-webkit-transform .25s;
  &:hover {
    transform: translateY(-4px);
  }
}

</style>

<template>
  <v-app v-scroll="onScroll" v-resize="onResize" id="Home">
    <v-app-bar
      app
      :color="bg"
      elevation="0"
      dark
      :height="onResize() ? '80': '64'"
      width="100vw"
      
    > 
    
      <a href="/" >
        <v-img 
          contain
          alt="Plan Do"
          title="Plan-Do"
          :src="logo"
          class="mr-2 "
          transition="scale-transition"
          style="cursor: pointer"   
          :max-width="onResize() ? '120': '200'"
        />
      </a>


      <v-spacer />
      
      <v-btn-toggle v-if="!onResize()" tile color="primary" group mandatory active-class="btn-nav" v-model="menuPpal">
        <v-btn v-for = "item of Menu" 
          :key="item.title"
          :text="item.text"
          :tile="item.tile" 
          :replace="item.replace" 
          :ripple="item.ripple"
          :rounded="item.rounded"
          :class="item.class"
          :color="item.color"
          @click.native="Goto(item.link,item.type)"

        >
          {{item.title}}
        </v-btn>
      </v-btn-toggle>
      
      <v-app-bar-nav-icon v-else @click.stop="drawer = !drawer" ></v-app-bar-nav-icon>


    </v-app-bar>

<!--- INI SERVICIOS --->
    <template v-if="!onResize()" >
      <v-navigation-drawer
        v-model="viewServices"
        
        fixed
        temporary
        
        width="100vw"
        color="transparent"
      >
        <v-row  no-gutters :style="onResize()? 'margin-top:105px' : 'margin-top:65px'">
          <v-col cols=12 md=12 lg=5 xl=4 class="white" >
            <div class="pt-5 ml-16 pl-2 font-weight-bold" @click="Goto('Servicios')" style="cursor: pointer">SERVICIOS</div>
            <v-list :min-height="onResize()? 'calc(100vh - 150px)' : 'calc(100vh - 110px)'"  class="pt-5 " dense >  
                <div
                  v-for="(item, index) in Servicios"
                  :key="index"
                >
                    <v-list-item class="px-0 py-0" :class="[item.bg]"    link>
                      <div class="white py-3 py-sm-0 "  >
                        <v-img eager  width="50px" :src="item.icono" class="mx-3 my-1" ></v-img>
                      </div>
                      <v-list-item-content class="white--text px-2" :class="[item.bg]" style="heigth:100%" @click="Goto(item.link)">
                        <div v-text="item.title" class="body-2 font-weight-bold"></div>
                      </v-list-item-content>
                      <v-list-item-action class="px-3">
                        <v-icon color="white">mdi-chevron-double-right</v-icon>
                      </v-list-item-action>
                    </v-list-item>
                    <v-list-item
                      v-for="child in item.items"
                      :key="child.title"
                    >
                      <v-list-item-content>
                        <v-list-item-title v-text="child.title" class="text-uppercase ml-16"></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                </div>
  
            </v-list>
          </v-col> 
          <v-col lg=7 xl=8 class="hidden-md-and-down pa-5"> 
          </v-col>
        </v-row>

      </v-navigation-drawer>

      <div 
        class="menu-activador "
        :class="viewServices?'active':''" 
        :style="'top:80px' "
        @click="viewServices=!viewServices"
        
      >
            <span class="menu-activador-linea"></span>
            <span class="menu-activador-linea"></span>
            <span class="menu-activador-linea"></span>
      </div>
    </template>
<!-- FIN SERVICIOS -->
    <template v-else>
      <v-navigation-drawer
        v-model="drawer"
 
        fixed
        temporary
        
        width="100vw"
        height="100vh"
        color="black"
        class=""
      > 
      <v-btn
        icon
        id="btnClose"
        style="position:fixed; top:10px; right:10px;"
      >
        <v-icon color="primary" size=36 @click="drawer=!drawer">mdi-close</v-icon>
      </v-btn>
        <v-sheet height="100vh" color="black" class="d-flex justify-center">


            <v-btn-toggle 
              tile 
              color="primary"
              group
              mandatory 
              active-class="btn-nav" 
              v-model="menuPpal" 
              class="d-flex flex-column justify-center " 

              
            >
              <v-btn v-for = "(item, index) of Menu" 
                :key="item.title"
                :text="item.text"
                :tile="item.tile" 
                :replace="item.replace" 
                :ripple="item.ripple"
                class="text-h5 btn-line white--text my-8"
                :class="index==0 ? 'd-none': ''"
                
                @click.native="Goto(item.link,item.type)"

              >
                {{item.title}}
              </v-btn>
            </v-btn-toggle>
        </v-sheet>

      </v-navigation-drawer>

    </template> 


    <v-fab-transition>
      <v-btn
        v-show="gotoTop"
        color="rgba(255,255,255,0.4)"
        dark
        small
        bottom
        fab
        tile
        right
        fixed
        class="v-btn--center"
        @click="$vuetify.goTo(0,{duration: 600,offset: 0, easing: 'easeInOutCubic'})"
        style="z-index:99999"
      >
        <v-icon color="primary" class="pa-0">mdi-36px mdi-chevron-up</v-icon>
      </v-btn>
    </v-fab-transition>

    <router-view ></router-view>


    <v-footer app absolute color="black" dark  style="" class="d-block">
        <v-row class="pt-3 px-5">
          <v-col cols=8 md=6 lg=4 class="caption text--white ">
            <ul  class="foot px-3 d-block d-md-flex justify-space-between " >
              <li  v-for = "item of Menu" :key="item.title" class="text-uppercase" @click="Goto(item.link)" style="cursor:pointer;">{{item.title}}</li>
            </ul> 
          </v-col>
          <v-col cols=4 md=6 lg=8 class="d-flex justify-end align-start">
            <!--
            <v-icon color="black" class="rounded white">mdi-linkedin</v-icon>         
            <v-icon class="mx-2">mdi-whatsapp</v-icon>
            -->
              <!--
              <img
                src="@/assets/linkedin.png"
                alt="Linkedin"
                width="22px"
                class="mx-2"
              />
              <img
                src="@/assets/whatsapp.png"
                alt="Whatsapp"
                width="22px"
                class="mx-2"
              />
              -->
              <v-hover
                v-for="ico of icons" :key="ico.ico"
                v-slot="{ hover }"
                open-delay="100"
                close-delay="100"
              >
                <a :href="ico.link" target="_blank" style="text-decoration:none">
                  <v-icon class="mx-2" :color="hover ? 'rgba(255,255,255,0.5)' : 'white'" >{{ico.ico}}</v-icon>
                </a>
                
              </v-hover>  
          </v-col>
        </v-row>
        <v-row  class="my-0 py-2 px-5">
          <v-col cols=12 class="caption tex--white">
            Copyright  PLAN<span class="primary--text px-1">&#9642;</span>DO | 2022. Todos los derechos reservados. 
          </v-col>
        </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import 'animate.css'

import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'

import carousel from 'vue-owl-carousel'

export default {
  name: 'App',
  components: { carousel , VueperSlides, VueperSlide },

 

  data: () => ({

    drawer: false,

    Menu:[
      {title:'Inicio',text:null, tile:null, replace:null, ripple:null, rounded:null,class:"d-none",link:'Home', type:'anclaje'},
      {title:'Nosotros',text:true, tile:true, replace:true, ripple:false, rounded:false,class:"btn-line body-2 white--text",link:'Somos',type:'anclaje'},
      {title:'Servicios',text:true, tile:true, replace:true, ripple:false, rounded:false,class:"btn-line body-2 white--text",link:'Servicios',type:'anclaje'},
      {title:'Búsquedas',text:true, tile:true, replace:true, ripple:false, rounded:false,class:"btn-line body-2 white--text",link:'Busquedas',type:'anclaje'},
      {title:'HABLEMOS!',text:false, tile:false, replace:false, ripple:false, rounded:true,class:"body-2 ml-1 ml-sm-5",color:"secondary lighten-1",link:'ContacUs',type:'anclaje'},

    ],

    servicio:true,
    Servicios:[
      {
        icono:'/images/iconos/seleccion_atraccion.svg',
        title:'SELECCIÓN & ATRACCIÓN DE TALENTO',
        bg:'primary',
        link:'seleccion_atraccion',
        items:[
          {title:'Búsquedas ejecutivas'},
          {title:'Búsquedas profesionales'},
          {title:'Búsqueda de personal operativo'},
          {title:'Programas de trainees y jovenes profesionales'}
        ]
      },
      { 
        icono:'/images/iconos/crecimiento_desarrollo.svg', 
        title:'CRECIMIENTO & DESARROLLO ORGANIZACIONAL', 
        bg:'grey',
        link:'crecimiento_organizacional',
        items:[
          {title:'Géstion del talento'},
          {title:'Géstion del cambio'},
          {title:'Géstion de equipos'},
          {title:'Desarrollo directivo y del liderazgo'},
          {title:'Metodología lego serious play'},
          {title:'Metodologías ágiles'}  
        ]
      },
      //{icon:'mdi-home-city', title:'CRECIMIENTO & DESARROLLO ORGANIZACIONAL'},
      //{icon:'mdi-home-city', title:'METODOLOGÍA LEGO SERIOUS PLAY6'},
      //{icon:'mdi-home-city', title:'DESARROLLO DE TRAINEES Y JÓVENES PROFESIONALES'},
      //{icon:'mdi-home-city', title:'IMPLEMENTACIÓN DE METODOLOGÍAS ÁGILES'},
    ],

    viewServices:false,
    menuPpal:0,
    offsetTop:0,
    icons:[
      {
        ico:'mdi-linkedin',
        title:'Linkedin',
        link:'https://www.linkedin.com/company/plandoconsulting',
        fn:''
      },
      {
        ico:'mdi-whatsapp',
        title:'Whatsapp',
        link:'https://api.whatsapp.com/send?phone=+543416835878&text=Hola,%20¿Cómo%20tal%20estás?%20Envíanos%20tu%20consulta!',
        fn:''
      },
    ],

  }),

  created: async function () {
    //console.log('create app')

    console.log(navigator)

    //console.log(this.$vuetify.breakpoint)
    this.offsetTop=window.scrollY
    this.onResize()
      
  },

  watch: {
    menuPpal(val, old){
      console.log("VAL MENU-->",val)
    },
  },
  computed:{
    //...mapGetters(['ROOT_API','MENU','getSnackBar','getSESSION','getUser']),

    logo: function() {
      //console.log("logo")
      return this.onResize() ?  "/images/logo_sm.svg" : "/images/logo.svg"
    },


    bg: function () {
      //return this.offsetTop<100 ? 'rgba(0,0,0,.5)' : 'black' 

      let r = (this.$route.name=='Home' || this.$route.name=='Secciones') ? (r=this.offsetTop<100 ? 'rgba(0,0,0,.5)' : 'black') : 'black'
      return r
      
    },

    gotoTop : function () {
      //console.log(this.offsetTop)
      return this.offsetTop>100
    },

    btnMobil : function () {
      
      return false
    },


  },

  methods: {

    Goto (url, type){
      /*
      console.log(this.$route)
      console.log(url, type)
      let {name,params,fullpath,path} = this.$route 

      if (type=='anclaje'){
        if (name=='Home' || name=='Secciones' ){
          this.$vuetify.goTo(`#${url}`,{duration: 600,offset: 0, easing: 'easeInOutCubic'})
        }
        else {
          this.$router.replace(`/${url}`,)
        }
      }
      else {
        this.$router.replace({name:url})
      }
      */
      this.viewServices=false
      this.drawer=false
      this.$vuetify.goTo(`#${url}`,{duration: 600,offset: 0, easing: 'easeInOutCubic'})
    },

    onFooter (entries, observer) {
        // More information about these options
        // is located here: https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
        //this.isIntersecting = entries[0].isIntersecting

        //console.log(entries,observer)
    },
    onResize () {
      //console.log(this.$vuetify.breakpoint)
      let {xs, sm, md, lg, xl,mobile} =  this.$vuetify.breakpoint      

      //console.log(this.$vuetify.breakpoint)
      //console.log(this.$vuetify)

      //console.log(window.orientation)


      return sm || xs
      //this.$store.dispatch("resizeApp",{ x: window.innerWidth, y: window.innerHeight, xs, sm, md, lg, xl,mobile })
    },
    onScroll (e){
      //console.log({e, window})
      //this.show.carousel = (window.scrollY >= parseInt(window.innerHeight/2))
      this.offsetTop=window.scrollY

    },

    //INI INTERSECT


    //FIN INTERSECT
  },


};
</script>

<style lang="scss">



.text-responsive-h {
  font-size: clamp(4.5rem, 10vw, 8rem);
  line-height: .7!important;
}

#clientes {
  height:200px !important;
}

.v-toolbar__extension {
    padding: 4px 4px !important;
}


.v-btn-toggle > .v-btn.v-btn {
    padding: 0 3px !important;
}

.menu-activador {
  position:fixed;
  left:15px;
  z-index:99;
  width: 40px;
  height: 30px;
  //margin: 0px auto;

  padding: 5px;

  background-color:rgba(0,0,0,.4);

  cursor: pointer;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:not(.active):hover {
    .menu-activador-linea {
      &:nth-child(1) {
        width:60%!important;
      }
      &:nth-child(3) {
        width:100%!important;
      }

    }
  }


  .menu-activador-linea {
    height: 3px;
    background-color: var(--v-anchor-base);
    opacity: 1;
    transform: translateY(0) rotate(0);
    transition: all 0.3s;

    &:nth-child(1) {
      width:100%;
    
    }
    &:nth-child(3) {
      width:60%;
    
    }

  }
  
  &.active .menu-activador-linea {
    &:nth-child(1) {
      width:100%;
      transform: translateY(9px) rotate(45deg);
    }
    &:nth-child(2) {
      opacity: 0;
    }
    &:nth-child(3) {
      width:100%;
      transform: translateY(-9px)rotate(-45deg);
    }
  }
}

.v-navigation-drawer {
  transition-duration: 0.5s!important;
}


:root {
  --animate-duration: 1s;
}

.v-btn--absolute.v-btn--top, .v-btn--fixed.v-btn--top {
    top: 45%!important;
}
.v-btn--absolute.v-btn--left, .v-btn--fixed.v-btn--left {
    left: 0!important;
}


ul.foot {
  list-style-type: none!important;

  li:before {
    content:"\25AA";
    color: #C9D401 !important;
    display: inline-block; 
    width: 1em;
    margin-left: -1em
  }
}


.theme--dark.v-btn.v-btn--icon {
  color: #C9D401 !important;
} 


.v-btn.v-btn-h24   {
  height: 24px!important;
}


.v-toolbar {
  transition: background-color 0.5s ease-in-out,
              height 0.5s ease  !important;  

 /* transition: transform 250ms ease-in-out,
              500ms ease  background-color, 
              0.2s cubic-bezier(0.4, 0, 0.2, 1) left, 
              0.2s cubic-bezier(0.4, 0, 0.2, 1) right, 
              280ms cubic-bezier(0.4, 0, 0.2, 1) box-shadow, 
              0.25s cubic-bezier(0.4, 0, 0.2, 1) max-width, 
              0.25s cubic-bezier(0.4, 0, 0.2, 1) width !important;*/
}


.v-toolbar__content,.v-toolbar__extension {


  .v-btn.v-size--default {
      height: 24px!important;
  }

  .v-btn.v-btn--rounded {
    border-radius: 8px!important;
  }

  .v-btn:before {
      background-color: transparent!important;
      border-radius: inherit;
      bottom: 0;
      color: inherit;
      content: "";
      left: 0;
      opacity: 0;
      pointer-events: none;
      position: absolute;
      right: 0;
      top: 0;
      //transition: opacity 0.2s cubic-bezier(0.4, 0, 0.6, 1); 
  }

  .v-btn:not(.v-btn--active):hover {
    color:#C9D401!important;
  }

  .v-btn-toggle > .v-btn.v-btn {
    opacity: 1!important;

    transition: all 250ms ease-in-out;


  }

  .v-btn.v-btn--active.btn-nav:not(.btn-line) {
    color: white !important;
    background-color:#C9D401!important;

    transition: all 250ms ease-in-out;

  }  


  .v-btn.btn-line.v-btn--active.btn-nav {
    color:#C9D401 !important;

    ::after {
        transform: scaleX(1);  
        opacity: 1;
    }

  }




  .v-btn.btn-line {
    text-transform: none!important;

    ::after{
      position: absolute;
      content: '';
      color:transparent;
      width: 100%;
      bottom:-10px;
      border-bottom: solid 2px #C9D401;
      transition: transform 250ms ease-in-out;
    }

    ::after {
      transform: scaleX(0);  
    }

    :hover::after { 
      transform: scaleX(1); 
      //color: #C9D401 !important;
    }

    :hover { 
      //transform: scaleX(1); 
      //color: #C9D401 !important;
    }
  } 

}

.v-navigation-drawer__content .v-sheet .v-btn-toggle--group  .btn-nav {
  color:#C9D401!important;
}

.vueperslides__arrow {
  color: var(--v-anchor-base)!important;
}



.card-actions-bottom {
  position: absolute;
  bottom: 0;
}

.vueperslide div {
  //-webkit-text-stroke: 1px black;
  /* offset-x | offset-y | blur-radius | color */
  text-shadow: 0.05em 0.05em 0.08em rgba(0,0,0,0.6);  
}


/*
.fade-enter-active, .fade-leave-active {
  transition: opacity 5s;
}
.fade-leave-active {
  transition: opacity 1s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
*/


div.glass {
  backdrop-filter: saturate(180%)  blur(4px);

}
</style>

<template>
    <v-dialog
      :value="true"
      @click:outside="$emit('close')"
      v-resize="onResize"
      :fullscreen="$vuetify.breakpoint.xs  "
      :width="width"
    >
      <v-card tile>
        <v-card-title
          class="text-h6 primary white--text d-flex justify-space-between"
          style="position: sticky; top:0; z-index: 10;"
        >
          <v-row no-gutters>
            <v-col cols="11">{{busqueda.titulo}}</v-col>

            <v-col class="text-right"><v-icon class="white--text" @click="$emit('close')">mdi-close</v-icon></v-col>
            
          </v-row>
         
        </v-card-title>

        <v-card-text>
          
        <v-row no-gutters :class="index==0 ? 'mt-10' : 'mt-5'" >
          <v-col cols=12 sm=3  lg=2  xl=2    order=1 order-sm=0>
            <div v-for="(link, idx) of busqueda.listLinks" class="d-block mb-10" :key="`link${index}_${idx}`">
              <div style="width:100%; " class="px-5 px-md-10  mt-10 mt-sm-0" >
                <a :href="link.link" target="_blank">
                <v-img
                  content
                  :src="`/${link.path}/${link.fileName}`"  
                  eager  
                  max-width="120px"        
                >
                </v-img>
                </a>
              </div>
              <div style="width:100%" class="px-5 px-md-10 " >
                <v-btn rounded x-small color="primary" class="mt-5 caption" :href="link.link" target="_blank">Postulate!!!</v-btn>
              </div>
            </div>


          </v-col>
          <v-col cols=12 sm=9  lg=10 xl=10 class="px-2 px-sm-0" >
            <v-row no-gutters>
              <v-col cols=12 class="body-2 px-2 ">
                <p class="my-2 font-weight-normal">{{busqueda.subtitulo}}</p>
                <p class="my-2 font-weight-normal" v-html="busqueda.detalle"></p>
                <p class="mt-4 mb-1 primary--text font-weight-medium">¿Qué buscamos?</p>
                <p v-for="(item, index) of busqueda.listQBuscamos" class="mb-1" :key="`qb${index}`" >{{item.texto}}</p>

                <p class="mt-4 mb-1 primary--text font-weight-medium">¿Para qué?</p>
                <p v-for="(item, index) of busqueda.listParaQue" class="mb-1" :key="`pq${index}`" >{{item.texto}}</p>

                <p class="mt-4 mb-1 primary--text font-weight-medium">¿Qué ofrecemos?</p>
                <p v-for="(item, index) of busqueda.listQOfrecemos" class="mb-1" :key="`qo${index}`" >{{item.texto}}</p>
              </v-col>
              
            </v-row>
          </v-col>
        </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
</template>

<script>

  export default {
    name: 'Busqueda',
    components: { },

    props: ['busqueda'],


    data: () => ({
      width:'90vw',

    }),

    methods: {
      onResize () {
        console.log(this.$vuetify.breakpoint)
        let {xs, sm, md, lg, xl,mobile} =  this.$vuetify.breakpoint  
        
        let r = {}
        if (xl) {
          this.width='60vw'
        }
        else if (lg) {
          this.width='70vw'
        }
        else if (md) {
          this.width='90vw'
        }


        //console.log(window.orientation)


        return r
        
      },
    },
  }
</script>
